import { call, put, select, all, fork } from 'redux-saga/effects';
import config from 'appConfig';
import {
  getCPMappingApi,
  getLaunchCheckApi,
  getPingCheckApi,
} from './initial.api';
import { SET_LAUNCH_INFO, SET_NOTIFICATION_MSG, notificationSelector } from './initial.action';
import { SET_CART_QTY_COUNT } from './feature/cart/cart.action';
import { CP_MAPPING_KEY } from './util/constantServices';
import { getItem, setItem } from './util/localStorageService';
import { getNotificationsSaga } from './feature/notifications/notifications.saga';
import {sendAnalytics} from 'hb-redux-store/analytics';
import { getMembershipBannerDetailSaga } from 'hb-redux-store/feature/halalboxPrime/hbPrime.saga'
import {getData, storeData} from 'hb-redux-store/util/mobileStorage';

const { setMapping } = config;

export function* getLaunchCheckSaga({ cb = null }) {
  // console.warn('getLaunchCheckSaga', cb);
  try {
    if (config.isApp && !config?.deviceDetails?.isPromiseProcessed && config?.setDeviceDetailsPromise) {
      try {
        const deviceDetails = yield call(config.setDeviceDetailsPromise);
        console.log('Device details yields: ', deviceDetails);  
      } catch (e) {
        console.log('Failed to get device details: ', e);  
      } 
    }

    const promises = [call(getLaunchCheckApi)];
    let cpMapping = null;
    if(!config.isApp) cpMapping = getItem(CP_MAPPING_KEY)
    else cpMapping = getData(CP_MAPPING_KEY)
    if ((!cpMapping || cpMapping.validTill < new Date().getTime())) {
      promises.push(call(getCPMappingApi));
    }
    let [launchResp, cpMapResp] = yield all(promises);
    if (launchResp?.data?.flushSeo) {
      cpMapResp = yield call(getCPMappingApi);
    }
    if (cpMapResp) {
      const d = new Date();
      d.setDate(d.getDate() + 1); // Refresh mapping every 24 hours(1 day)
      const validTill = d.getTime();
      const mappingData =  { ...cpMapResp.data, validTill }
      if(!config.isApp) setItem(CP_MAPPING_KEY, mappingData);
      else storeData(CP_MAPPING_KEY, mappingData);
      if(setMapping) {
        setMapping(mappingData);
      }
    }
    yield call(getNotificationsSaga, {});
    yield fork(getMembershipBannerDetailSaga);
    const { data } = launchResp;
    data.currencySymbol = data.currencySymbol || '$';
    data.countryCode = data.countryCode || '91';
    data.ccNumber = data.ccNumber || '8080447766';
    if(config.isApp){
      const payload = {
        ...data,
        ...(data?.customerAddress || {}),
        Name: data?.customerFullName,
        Identity: config.getDeviceId(),
        Email: data?.email,
        Phone: `+${data.countryCode}${data?.mobile}`,
        Location: data?.adcCode,
      };

      sendAnalytics({
        payload,
        onUserLogin: true,
        clevertapPriority: 1,
      });
    }
    yield put({ type: SET_CART_QTY_COUNT, data: data.totalCartQuantity });
    yield put({
      type: SET_LAUNCH_INFO,
      data,
    });
    const traits = {};
    if (data.customerFullName) traits.name = data.customerFullName;
    if (data.email) traits.email = data.email;
    if (data.mobile) traits.mobile = data.mobile;
    // if(config.isApp && cb) {
    if (cb) cb(traits);
    // }
    if (config?.setAnalyticsLaunchData) config.setAnalyticsLaunchData(data);
    if (!config?.isApp && config?.clevertap) {
      const clevertapData = { Identity: config.getDeviceId() };
      if (traits.name) clevertapData.Name = traits.name;
      if (traits.email) clevertapData.Email = traits.email;
      if (traits.mobile) clevertapData.Phone = `+${data.countryCode}${traits.mobile}`;
      const clevertapPayload = {...clevertapData, ...data}
      config.clevertap.sendCleverTapUserDetails({ Site: clevertapPayload });
    }
  } catch (e) {
    console.error('Err @getLaunchCheckSaga: ', e);
    yield put({
      type: SET_LAUNCH_INFO,
      data: { initialCheckDone: true },
    });
  }
}

const delay = time => new Promise(resolve => setTimeout(resolve, time));

export function* setAppNotificationSaga ({ data }) {
  const notification = yield select(notificationSelector);

  yield put({
    type: SET_NOTIFICATION_MSG,
    data: {
      show: false,
      message: '',
    }
  });
  if(notification.show) {
    yield call(delay, 50);
  }
  yield put({
    type: SET_NOTIFICATION_MSG,
    data: {
      show: true,
      message: data
    }
  });
};

export function* mergeLaunchInfoSaga ({ data, cb }) {
  cb();
  yield put({
    type: SET_LAUNCH_INFO,
    data: data,
  });
}

export function* getPingCheckSaga() {
  try {
    const resp = yield call(getPingCheckApi);
    console.log('Response from ping api: ', resp);
    yield put({
      type: SET_LAUNCH_INFO,
      data: { pingResponse: JSON.stringify(resp) },
    });
  } catch (e) {
    console.log('Err from ping api: ', e);
    yield put({
      type: SET_LAUNCH_INFO,
      data: { pingResponse: 'Ping failed. Check browser console for error.' },
    });
  }
}
